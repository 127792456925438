<template>
    <div>
        <template v-if="pendingTeamChange">
            <Loading />
        </template>
        <div v-else-if="canAccessTeam && team">
            <Teleport v-if="mounted" to="#platform-banner">
                <div v-if="isVisitingAdmin" class="ff-banner" data-el="banner-team-as-admin">You are viewing this team as an Administrator</div>
                <TeamSuspendedBanner v-if="team.suspended" :team="team" />
                <SubscriptionExpiredBanner v-else :team="team" />
                <TeamTrialBanner v-if="team.billing?.trial" :team="team" />
            </Teleport>
            <router-view />
        </div>
        <div v-else-if="!canAccessTeam">
            <TeamInstances :dashboard-role-only="true" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Loading from '../../components/Loading.vue'
import SubscriptionExpiredBanner from '../../components/banners/SubscriptionExpired.vue'
import TeamSuspendedBanner from '../../components/banners/TeamSuspended.vue'
import TeamTrialBanner from '../../components/banners/TeamTrial.vue'
import { Roles } from '../../utils/roles.js'

import TeamInstances from './Instances.vue'

export default {
    name: 'TeamPage',
    components: {
        TeamInstances,
        Loading,
        SubscriptionExpiredBanner,
        TeamSuspendedBanner,
        TeamTrialBanner
    },
    async beforeRouteUpdate (to, from, next) {
        // even if billing is not yet enabled, users should be able to see these screens,
        // in order to delete the project, or setup billing
        await this.checkRoute(to)
        next()
    },
    data () {
        return {
            mounted: false
        }
    },
    computed: {
        ...mapState('account', ['user', 'team', 'teamMembership', 'pendingTeamChange', 'features']),
        ...mapGetters('account', ['noBilling', 'isAdminUser']),
        isVisitingAdmin: function () {
            return (this.teamMembership.role === Roles.Admin)
        },
        isTrialEnded: function () {
            if (this.team.billing?.trialEndsAt) {
                const trialEndDate = new Date(this.team.billing?.trialEndsAt)
                return trialEndDate < Date.now()
            }
            return true
        },
        canAccessTeam: function () {
            return this.isAdminUser || this.teamMembership?.role >= Roles.Viewer
        }
    },
    watch: {
        '$route.params.team_slug' (slug) {
            this.$store.dispatch('account/setTeam', slug)
        },
        team () {
            this.checkRoute(this.$route)
        }
    },
    mounted () {
        this.mounted = true
    },
    async beforeMount () {
        this.checkRoute(this.$route)
    },
    methods: {
        checkRoute: async function (route) {
            const allowedRoutes = []

            if (this.team) {
                allowedRoutes.push('/team/' + this.team.slug + '/billing')
                allowedRoutes.push('/team/' + this.team.slug + '/settings')
                allowedRoutes.push('/team/' + this.team.slug + '/settings/general')
                allowedRoutes.push('/team/' + this.team.slug + '/settings/danger')
                allowedRoutes.push('/team/' + this.team.slug + '/settings/change-type')

                if (allowedRoutes.indexOf(route.path) === -1) {
                    // if we're on a path that requires billing
                    await this.checkBilling()
                }
            }
        },
        checkBilling: async function () {
            // Team Billing
            if (this.noBilling) {
                this.$router.push({
                    path: `/team/${this.team.slug}/billing`
                })
            }
        }
    }
}
</script>
